// import logo from './logo.svg';
import './App.css';
import Profile from './Profile.jsx';

function App() {
  return (
    <div clasName='place-self-center'>
      <Profile></Profile>
    </div>
  );
}

export default App;
